import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  source: yup
    .string()
    .required('Source is required')
    .test(
      'is-https',
      "Source must start with 'https://'",
      value => value && value.startsWith('https://')
    ),
  composition: yup.string().required('Composition is required'),
  aeAssetType: yup.string().required('Asset type is required'),
  assets: yup.array().of(
    yup.object().shape({
      composition: yup.string().required('Composition is required'),
      assetType: yup.string().nullable(),
      layerName: yup.string().required('Layer name is required'),
      property: yup.string().required('Property is required'),
      value: yup.string().nullable(),
      expression: yup.string().nullable(),
    })
  ),
});
