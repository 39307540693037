/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { Typography, TextInput, Button, Toast } from '@team-seenit/atoms';
import { createPortal } from 'react-dom';
import { mutations } from './CreateNewGroup.gql';
import * as Styled from './CreateNewGroup.styled';
import { FormError } from '../FormError';

export const CreateNewGroup = ({
  type,
  onClick,
}: {
  type: 'organisation' | 'default';
  onClick?: () => void;
}) => {
  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const toastRef = useRef();
  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const name = watch('name');

  const [createFloatingOrg] = useMutation(mutations.createFloatingOrg, {
    onCompleted: () => {
      onClick();
      showToast('Group created successfully');
    },
  });

  const handleSubmit = () => {
    if (isValid)
      createFloatingOrg({
        variables: { data: { name, purpose: `${type}Branding` } },
      });
  };

  const rootElement = document.getElementById('root');

  return (
    <Styled.Wrapper>
      <Typography variant="header">Create new group</Typography>
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'A name must be provided',
        }}
        render={({ field }) => (
          <Styled.InputWrapper>
            <Typography lines={0} variant="label" css={{ marginBottom: '4px' }}>
              Group name
            </Typography>
            <TextInput {...field} placeholder="Group name goes here..." />
            <FormError errors={errors} name="name" />
          </Styled.InputWrapper>
        )}
      />
      <Button
        animation="pulse"
        id="createNewGroupSubmit"
        large
        onClick={handleSubmit}
        width="100%"
      >
        Create new group
      </Button>
      {!!rootElement &&
        createPortal(
          <Toast
            autoClose="3000"
            containerId="CreateUpdateGroupToastContainer"
            position="top-center"
            ref={toastRef}
            toastId="CreateUpdateGroupToast"
          />,
          rootElement
        )}
    </Styled.Wrapper>
  );
};
