import React from 'react';
import { Typography } from '@team-seenit/atoms';
import { ErrorMessage } from '@hookform/error-message';

export const FormError = ({
  errors,
  name,
}: {
  errors: {
    [x: string]: any;
  };
  name: string;
}) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => {
        return message && <Typography color="red">{message}</Typography>;
      }}
    />
  );
};
