import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginTop: '20px',
}));

export const InputsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const TabSideWrapper = styled.div(() => ({
  flex: 1,
}));

export const InputWrapper = styled.div(() => ({
  marginBottom: '5px',

  '& > *': {
    ':first-child': {
      marginBottom: '4px',
    },
  },
}));

export const TitleIcon = styled.div(() => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',

  '& > *': {
    ':last-child': {
      color: '#e02020',
    },
  },
}));

export const ReorderIcons = styled.div(() => ({
  display: 'flex',
  gap: '10px',
}));

export const AssetsWrapper = styled.div(() => ({
  background: 'white',
  height: '100%',
  cursor: 'move',
  border: 'solid 2.5px #cecece',
  borderRadius: '8px',
  padding: '8px 18px 18px',
  overflow: 'hidden',
}));

export const AssetTopLine = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
