/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyQuery } from '@apollo/client';
import { AfterEffectsCurrentState } from './CurrentState';
import { AfterEffectsForm } from './Form/AfterEffectsForm';
import * as Styled from './AfterEffects.styled';
import { defaultFormProps, validationSchema } from './helpers';
import { queries } from './AfterEffects.gql';

export const AfterEffects = ({ orgId }: { orgId: string }) => {
  const [selectedAsset, setSelectedAsset] = useState();
  const methods = useForm({
    defaultValues: { ...defaultFormProps },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const [getAeAssets, { data: getAeAssetsData, refetch }] = useLazyQuery(
    queries.getAeAssets
  );

  useEffect(() => {
    if (orgId) {
      getAeAssets({ variables: { filter: { orgId } } });
    }
  }, [orgId]);

  return (
    <FormProvider {...methods}>
      <Styled.TabSideWrapper>
        <AfterEffectsForm
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
          orgId={orgId}
          refetchAssets={refetch}
        />
      </Styled.TabSideWrapper>
      <Styled.TabSideWrapper>
        <AfterEffectsCurrentState
          aeAssets={getAeAssetsData?.seenit?.aeAssets}
          setSelectedAsset={setSelectedAsset}
          selectedAsset={selectedAsset}
          refetchAssets={refetch}
        />
      </Styled.TabSideWrapper>
    </FormProvider>
  );
};
