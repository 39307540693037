import { gql } from '@apollo/client';

export const queries = {
  getBrand: gql`
    query AdminBrand($brandId: ID!) {
      seenit {
        adminBrand(brandId: $brandId) {
          brandId
          closingVideos {
            editorMediaId
            title
          }
          description
          lowerThirds {
            lowerThirdId
            name
          }
          lowerThirdAeAsset {
            name
            aeAssetId
          }
          musicTracks {
            editorMediaId
            title
          }
          name
          openingVideos {
            editorMediaId
            title
          }
          orientation
          logo {
            editorMedia {
              editorMediaId
              title
            }
            height
            positionX
            positionY
            width
          }
        }
      }
    }
  `,

  getMediaByType: gql`
    query BrandsGetEditorMedia($filter: EditorMediaFilterInput) {
      seenit {
        editorMedia(filter: $filter) {
          editorMediaId
          title
        }
      }
    }
  `,

  getShareableBrandingGroups: gql`
    query getShareableBrandingGroups(
      $filter: FloatingOrgFilterInput
      $sort: [SortGenericInput]
    ) {
      seenit {
        floatingOrgs(filter: $filter, sort: $sort) {
          name
          orgId
          purpose
        }
      }
    }
  `,
  getEditorAdminBrands: gql`
    query EditorConfigGetAdminBrands($filter: BrandFilterInput) {
      seenit {
        adminBrands(filter: $filter) {
          brandId
          name
          orgIds
        }
      }
    }
  `,

  getLowerThirds: gql`
    query BrandsGetLowerThirds($filter: EditorAdminGenericFilterInput) {
      seenit {
        lowerThirds(filter: $filter) {
          lowerThirdId
          name
        }
      }
    }
  `,

  getAeAssets: gql`
    query BrandsGetAeAssets($filter: EditorAdminAeAssetFilterInput) {
      seenit {
        aeAssets(filter: $filter) {
          aeAssetId
          name
          aeAssetType
        }
      }
    }
  `,

  getAdminShareableBrands: gql`
    query AdminShareableBrands {
      seenit {
        adminShareableBrands {
          brandId
          name
          orgIds
        }
      }
    }
  `,

  getBrandsOrgStats: gql`
    query BrandsGetOrgStats($orgId: ID) {
      seenit {
        admin {
          organisations(orgId: $orgId) {
            tier {
              name
              tierId
            }
          }
        }
      }
    }
  `,
};

export const mutations = {
  createBrand: gql`
    mutation BrandsBriefCreateBrand($data: CreateBrandInput!) {
      seenit {
        createBrand(data: $data) {
          brandId
          createdBy
        }
      }
    }
  `,
  updateBrand: gql`
    mutation UpdateBrand($brandId: ID!, $data: UpdateBrandInput!) {
      seenit {
        updateBrand(brandId: $brandId, data: $data) {
          brandId
        }
      }
    }
  `,
  deleteBrand: gql`
    mutation DeleteBrand($brandId: ID!) {
      seenit {
        deleteBrand(brandId: $brandId) {
          success
        }
      }
    }
  `,
};

export const destructureDataForOrgBrands = (data = {}) => {
  const { seenit: { adminBrands = [] } = {} } = data;

  return {
    name: 'test',
    icon: 'brand_editor',
    title: 'Total',
    stats: adminBrands.length || 0,
    color: 'blue',
    items: adminBrands || [],
  };
};
