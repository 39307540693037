/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, TextInput, IconButton } from '@team-seenit/atoms';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as Styled from '../AfterEffects.styled';
import { FormError } from '../../FormError';

export const Assets = ({ index, id }: { index: number; id: string }) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  const assets = watch('assets');

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const handleMoveUp = () => {
    setValue('assets', arrayMove(assets, index, index - 1));
  };

  const handleMoveDown = () => {
    setValue('assets', arrayMove(assets, index, index + 1));
  };

  const handleOnKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') e.stopPropagation();
  };

  return (
    <Styled.AssetsWrapper
      style={style}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <Styled.AssetTopLine>
        <Styled.TitleIcon>
          <Typography variant="subheader">Asset #{index + 1}</Typography>
          <IconButton
            icon="delete"
            onClick={() => {
              const newAssets = assets.filter((_, i) => i !== index);
              setValue('assets', newAssets);
            }}
            variant="outlineRed"
          />
        </Styled.TitleIcon>
        {assets.length > 1 && (
          <Styled.ReorderIcons>
            {index !== assets.length - 1 && (
              <IconButton
                icon="chevron_down"
                onClick={handleMoveDown}
                variant="outline"
              />
            )}
            {index !== 0 && (
              <IconButton
                icon="chevron_up"
                onClick={handleMoveUp}
                variant="outline"
              />
            )}
          </Styled.ReorderIcons>
        )}
      </Styled.AssetTopLine>
      <Controller
        name={`assets[${index}].composition`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Composition
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].composition`) ?? ''}
              placeholder="After Effects composition goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].composition`} />
          </Styled.InputWrapper>
        )}
      />
      <Controller
        name={`assets[${index}].assetType`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Asset Type
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].assetType`) ?? ''}
              placeholder="After Effects asset type goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].assetType`} />
          </Styled.InputWrapper>
        )}
      />
      <Controller
        name={`assets[${index}].layerName`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Layer Name
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].layerName`) ?? ''}
              placeholder="After Effects layerName goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].layerName`} />
          </Styled.InputWrapper>
        )}
      />
      <Controller
        name={`assets[${index}].property`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Property
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].property`) ?? ''}
              placeholder="After Effects property goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].property`} />
          </Styled.InputWrapper>
        )}
      />
      <Controller
        name={`assets[${index}].value`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Value
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].value`) ?? ''}
              placeholder="After Effects value goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].value`} />
          </Styled.InputWrapper>
        )}
      />
      <Controller
        name={`assets[${index}].expression`}
        control={control}
        render={({ field }) => (
          <Styled.InputWrapper onClick={e => e.stopPropagation()}>
            <Typography lines={0} variant="label">
              Expression
            </Typography>
            <TextInput
              {...field}
              onKeyDown={handleOnKeyDown}
              value={getValues(`assets[${index}].expression`) ?? ''}
              placeholder="After Effects expression goes here..."
            />
            <FormError errors={errors} name={`assets[${index}].expression`} />
          </Styled.InputWrapper>
        )}
      />
    </Styled.AssetsWrapper>
  );
};
