/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createPortal } from 'react-dom';
import {
  Typography,
  TextInput,
  Button,
  Toast,
  Pill,
  ActionCard,
  Select,
} from '@team-seenit/atoms';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { mutations, queries } from '../AfterEffects.gql';
import { AfterEffectsFormProps } from './AfterEffectsForm.types';
import * as Styled from '../AfterEffects.styled';
import { Error } from '../../../../molecules';
import { FormError } from '../../FormError';
import { Assets } from './AfterEffectsFormAssets';
import { validationSchema } from '../helpers';
import { AeAssetEnum } from '../../EditorConfigEnums';

export const AfterEffectsForm = ({
  orgId,
  selectedAsset,
  setSelectedAsset,
  refetchAssets,
}: AfterEffectsFormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const toastRef = useRef();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 1, // Enable sort function when dragging 1px
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  const rootElement = document.getElementById('root');

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const assets = watch('assets');

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = assets.findIndex(({ id }) => id === active.id);
      const newIndex = assets.findIndex(({ id }) => id === over.id);

      setValue('assets', arrayMove(assets, oldIndex, newIndex));
    }
  };

  const [
    createAeAsset,
    { error: createAeAssetError, reset: resetCreateAeAsset },
  ] = useMutation(mutations.createAeAsset, {
    onCompleted: () => {
      reset();
      refetchAssets();
      setSelectedAsset(null);
      showToast('AE Asset created successfully');
    },
  });

  const [
    updateAeAsset,
    { error: updateAeAssetError, reset: resetUpdateAeAsset },
  ] = useMutation(mutations.updateAeAsset, {
    onCompleted: () => {
      reset();
      refetchAssets();
      setSelectedAsset(null);
      showToast('AE Asset updated successfully');
    },
  });

  const [getAeAsset] = useLazyQuery(queries.getAeAsset, {
    onCompleted: data => {
      const aeAsset = data?.seenit?.aeAsset;
      if (aeAsset) {
        setValue('name', aeAsset.name);
        setValue('source', aeAsset.source);
        setValue('composition', aeAsset.composition);
        setValue('aeAssetType', aeAsset.aeAssetType);
        if (aeAsset.assets) {
          const newAssets = aeAsset.assets.map((asset, i) => {
            const newAsset = Object.fromEntries(
              Object.entries(
                validationSchema.fields.assets.innerType.fields
              ).map(([key]) => {
                return [key, asset[key]];
              })
            );
            return { ...newAsset, id: `asset-${i}` };
          });

          setValue('assets', newAssets);
        }
        trigger();
      }
    },

    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (selectedAsset) getAeAsset({ variables: { aeAssetId: selectedAsset } });
    resetUpdateAeAsset();
    resetCreateAeAsset();
  }, [selectedAsset]);

  const prepareData = data => {
    const preparedAssets = data.assets.map((asset, i) => {
      const {
        assetType,
        composition,
        expression,
        layerName,
        property,
        value,
      } = asset;

      return {
        assetType,
        composition,
        expression,
        layerName,
        orderNumber: i,
        property,
        value,
      };
    });

    return {
      ...data,
      assets: preparedAssets,
    };
  };

  const onCreateClick = data => {
    createAeAsset({ variables: { data: { ...prepareData(data), orgId } } });
  };

  const onUpdateClick = data => {
    updateAeAsset({
      variables: {
        data: prepareData(data),
        aeAssetId: selectedAsset,
      },
    });
  };

  return (
    <Styled.Wrapper>
      <Pill
        color="red"
        readOnly
        text={
          selectedAsset
            ? `Updating after effects asset`
            : `Creating after effects asset`
        }
      />
      <Typography variant="subheader">{selectedAsset}</Typography>
      <form
        onSubmit={handleSubmit(selectedAsset ? onUpdateClick : onCreateClick)}
      >
        <Styled.InputsWrapper>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography lines={0} variant="label">
                  Source
                </Typography>
                <TextInput
                  {...field}
                  value={getValues('source') ?? ''}
                  placeholder="After Affects project source goes here..."
                />
                <FormError errors={errors} name="source" />
              </Styled.InputWrapper>
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography lines={0} variant="label">
                  Name
                </Typography>
                <TextInput
                  {...field}
                  value={getValues('name') ?? ''}
                  placeholder="After Effects asset name goes here..."
                />
                <FormError errors={errors} name="name" />
              </Styled.InputWrapper>
            )}
          />
          <Controller
            name="composition"
            control={control}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography lines={0} variant="label">
                  Composition
                </Typography>
                <TextInput
                  {...field}
                  value={getValues('composition') ?? ''}
                  placeholder="After Effects composition goes here..."
                />
                <FormError errors={errors} name="composition" />
              </Styled.InputWrapper>
            )}
          />
          <Controller
            name="aeAssetType"
            control={control}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography lines={0} variant="label">
                  aeAssetType
                </Typography>
                <Select
                  {...field}
                  readOnly={selectedAsset}
                  options={[
                    { text: 'Please select an aeAssetType', value: -1 },
                    ...Object.values(AeAssetEnum).map(curr => ({
                      text: curr,
                      value: curr,
                    })),
                  ]}
                  id="aeAssetType"
                  value={getValues('aeAssetType') ?? -1}
                  dataEl="AeAssetTypeSelect"
                  onChange={val => {
                    if (val === '-1') setValue('aeAssetType', null);
                    else setValue('aeAssetType', val);
                    trigger('aeAssetType');
                  }}
                />
                <FormError errors={errors} name="aeAssetType" />
              </Styled.InputWrapper>
            )}
          />
          <Typography variant="header">Assets</Typography>
          <DndContext
            onDragEnd={handleDragEnd}
            sensors={sensors}
            collisionDetection={closestCenter}
          >
            <SortableContext
              strategy={verticalListSortingStrategy}
              items={assets}
            >
              {assets.map((asset, index) => (
                <Assets key={asset.id} index={index} id={asset.id} />
              ))}
            </SortableContext>
          </DndContext>
          <ActionCard
            cardStyle="ghost"
            cardStyleHover="ghostHover"
            height="128px"
            icon="plus"
            mainText="Add a new asset"
            onClick={() => {
              setValue('assets', [
                ...assets,
                {
                  id: `asset-${assets.length}`,
                  ...Object.fromEntries(
                    Object.entries(
                      validationSchema.fields.assets.innerType.fields
                    ).map(([key]) => {
                      return [key, ''];
                    })
                  ),
                },
              ]);
            }}
            width="128px"
          />
          <Button
            dataEl="MediaConfigSubmitButton"
            id="openingMediaSubmit"
            large
            type="submit"
            width="100%"
          >
            {selectedAsset ? 'Update' : 'Create'}
          </Button>
          {createAeAssetError ||
            (updateAeAssetError && (
              <Error rawErrors={createAeAssetError || updateAeAssetError} />
            ))}

          {!!rootElement &&
            createPortal(
              <Toast
                dataEl="AfterEffectsToast"
                autoClose="3000"
                containerId="AfterEffectsToastContainer"
                position="top-center"
                ref={toastRef}
                toastId="AfterEffectsToast"
              />,
              rootElement
            )}
        </Styled.InputsWrapper>
      </form>
    </Styled.Wrapper>
  );
};
