import styled from 'styled-components';

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
  gap: '10px',
}));

export const CardContentWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  marginTop: '20px',
}));

export const TabContentWrapper = styled.div(() => ({
  display: 'flex',
  gap: '20px',
}));

export const BrandActionWrapper = styled.div(() => ({
  marginTop: '20px',
  border: 'solid 1px #e8e8e8',
  borderRadius: '8px',
  padding: '12px',
  '>:first-child': {
    marginBottom: '10px',
  },
}));
