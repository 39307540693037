import styled from 'styled-components';

export const TitleWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',

  '& > *': {
    ':last-child': {
      color: '#e02020',
    },
  },
}));

export const InputField = styled.div(() => ({}));
