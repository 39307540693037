/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typography, Select, TextInput } from '@team-seenit/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import { BrandsFormSectionSelections } from './BrandsFormSectionSelections';
import * as Styled from '../Brands.styled';
import { defaultFormProps, prepareEditorMediaSelect } from '../helpers';
import { queries } from '../Brands.gql';
import { FormError } from '../../FormError';
import { BrandsSectionBriefProps } from './BrandsForm.types';
import { isNumber } from '../../helpers';

export const BrandsFormSection = ({
  dataEl = 'ThemeSectionBrief',
  section,
  orgId,
}: BrandsSectionBriefProps) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [getWatermarkMedia, { data: watermarkMediaData }] = useLazyQuery(
    queries.getMediaByType
  );

  useEffect(() => {
    getWatermarkMedia({
      variables: { filter: { mediaType: 'watermarks', orgId } },
    });
  }, [orgId]);

  const { [section]: sectionData = {} } = defaultFormProps as any;
  const sectionKeys = Object.keys(sectionData);

  if (section === 'selections') {
    return <BrandsFormSectionSelections dataEl={dataEl} />;
  }

  return (
    <Styled.InputFields>
      {sectionKeys.includes('editorMediaId') && (
        <Controller
          name={`${section}.editorMediaId`}
          control={control}
          render={({ field }) => (
            <Styled.InputField>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Editor image Id
              </Typography>
              <Select
                {...field}
                dataEl={dataEl}
                options={prepareEditorMediaSelect(watermarkMediaData)}
                id="editorMedia"
                value={field.value ?? -1}
              />
            </Styled.InputField>
          )}
        />
      )}

      {sectionKeys.includes('positionX') && (
        <Controller
          name={`${section}.positionX`}
          control={control}
          render={({ field }) => (
            <Styled.InputField>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                positionX
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image positionX"
                value={getValues(`${section}.positionX`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} name={`${section}.positionX`} />
            </Styled.InputField>
          )}
        />
      )}

      {sectionKeys.includes('positionY') && (
        <Controller
          name={`${section}.positionY`}
          control={control}
          render={({ field }) => (
            <Styled.InputField>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                positionY
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image positionY"
                value={getValues(`${section}.positionY`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} name={`${section}.positionY`} />
            </Styled.InputField>
          )}
        />
      )}

      {sectionKeys.includes('height') && (
        <Controller
          name={`${section}.height`}
          control={control}
          render={({ field }) => (
            <Styled.InputField>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                height
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image height"
                value={getValues(`${section}.height`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} name={`${section}.height`} />
            </Styled.InputField>
          )}
        />
      )}

      {sectionKeys.includes('width') && (
        <Controller
          name={`${section}.width`}
          control={control}
          render={({ field }) => (
            <Styled.InputField>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                width
              </Typography>
              <TextInput
                {...field}
                dataEl={dataEl}
                placeholder="Image width"
                value={getValues(`${section}.width`) ?? ''}
                onChange={val => {
                  if (isNumber(val)) {
                    field.onChange(parseFloat(val));
                  } else field.onChange(val);
                }}
              />
              <FormError errors={errors} name={`${section}.width`} />
            </Styled.InputField>
          )}
        />
      )}
    </Styled.InputFields>
  );
};
