/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType } from 'yup';
import * as Styled from './Brands.styled';
import { BrandsForm } from './Form';
import { BrandsCurrentState } from './CurrentState';
import { defaultFormProps } from './helpers';
import { BrandsProps } from './Brands.types';
import { queries } from './Brands.gql';
import { OrgTypeEnum } from '../EditorConfigEnums';
import { validationSchema } from './helpers/validationSchema';

export const Brands = ({ dataEl = 'Brands', orgId, orgType }: BrandsProps) => {
  const [selectedBrand, setSelectedBrand] = useState();

  type FormValues = InferType<typeof validationSchema>;

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { ...defaultFormProps },
    resolver: yupResolver(validationSchema),
  });

  const [
    fetchAdminOrgBrands,
    {
      data: fetchAdminOrgBrandsData,
      error: fetchAdminOrgBrandsError,
      refetch: fetchAdminOrgBrandsRefetch,
    },
  ] = useLazyQuery(queries.getEditorAdminBrands, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    fetchSharedOrgBrands,
    {
      data: fetchSharedOrgBrandsData,
      error: fetchSharedOrgBrandsError,
      refetch: fetchSharedOrgBrandsRefetch,
    },
  ] = useLazyQuery(queries.getEditorAdminBrands, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (orgId && orgType) {
      fetchAdminOrgBrands({
        variables: { filter: { orgId } },
      });
      if (orgType === OrgTypeEnum.AllOrganisations) {
        fetchSharedOrgBrands({
          variables: { filter: { sharedBrandsForOrgId: orgId } },
        });
      }
    }
  }, [orgId, orgType]);

  const { reset } = methods;

  const error = { ...fetchAdminOrgBrandsError, ...fetchSharedOrgBrandsError };

  return (
    <FormProvider {...methods}>
      <Styled.TabSideWrapper dataEl={dataEl}>
        <BrandsForm
          dataEl="BrandsForm"
          refetchBrands={fetchAdminOrgBrandsRefetch}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          orgId={orgId}
        />
      </Styled.TabSideWrapper>

      <Styled.TabSideWrapper>
        <BrandsCurrentState
          dataEl="BrandsCurrentState"
          brands={fetchAdminOrgBrandsData}
          sharedBrands={fetchSharedOrgBrandsData}
          refetchBrands={fetchAdminOrgBrandsRefetch}
          refetchSharedBrands={fetchSharedOrgBrandsRefetch}
          error={error}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          orgType={orgType}
          orgId={orgId}
          resetForm={reset}
        />
      </Styled.TabSideWrapper>
    </FormProvider>
  );
};
