import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Tabs,
  Select,
  Radio,
  Button,
  Typography,
  Toast,
} from '@team-seenit/atoms';
import { createPortal } from 'react-dom';
import { SimpleModal } from '@team-seenit/molecules';
import { mutations, queries } from './EditorConfig.gql';
import {
  GET_ORGANISATIONS_QUERY,
  destructureDataForOrganisations,
} from '../QuickEditsConfig/Organisations.gql';
import { GET_TIERS_QUERY } from '../Tiers/Tiers.gql';
import * as Styled from './EditorConfig.styled';
import { Brands } from './BrandsSubPageOrganism';
import { LowerThirds } from './LowerThirdsOrganism';
import { EditorOrgAssets } from './EditorOrgAssetsOrganism';
import { MediaUploadOrganism } from './MediaUploadOrganism';
import { CreateNewGroup } from './CreateNewGroup';
import { ChangeTitle } from './ChangeTitle';
import { OrgTypeEnum } from './EditorConfigEnums';
import { AfterEffects } from './AfterEffectsOrganism/AfterEffects';

export function EditorConfig({ dataEl }: { dataEl: string }) {
  const [currentOrgId, setCurrentOrgId] = useState<undefined>();
  const [currentBrandId, setCurrentBrandId] = useState<undefined>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<undefined>();
  const [orgType, setOrgType] = useState();
  const [orgName, setOrgName] = useState<string>();
  const [editTitle, setEditTitle] = useState<boolean>();

  const toastRef = useRef();

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const {
    data: floatingOrgsDefaultBrandingData,
    refetch: refetchDefaultGroups,
  } = useQuery(queries.getFloatingOrgs, {
    fetchPolicy: 'cache-first',
    variables: { filter: { purpose: 'defaultBranding' } },
  });

  const {
    data: floatingOrgsOrgBrandingData,
    refetch: refetchOrganisationGroups,
  } = useQuery(queries.getFloatingOrgs, {
    fetchPolicy: 'cache-first',
    variables: { filter: { purpose: 'organisationBranding' } },
  });

  const { data: organisationsData, error: organisationsError } = useQuery(
    GET_ORGANISATIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { orgType: 'team' },
    }
  );

  const [
    getTiers,
    { data: tiersData, error: tiersError, loading: tiersLoading },
  ] = useLazyQuery(GET_TIERS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [getOrgBrands, { data: orgBrandsData }] = useLazyQuery(
    queries.getOrgBrands,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [
    deleteGroup,
    { error: deleteGroupError, reset: resetDeleteGroup },
  ] = useMutation(mutations.deleteGroup, {
    onCompleted: () => {
      setCurrentOrgId(undefined);
      if (orgType === OrgTypeEnum.DefaultBrandGroups) refetchDefaultGroups();
      if (orgType === OrgTypeEnum.OrganisationBrandGroups)
        refetchOrganisationGroups();
      setConfirmDeleteModal(false);
      showToast('Group deleted successfully');
    },
  });

  const [updateGroup, { loading: groupDataLoading }] = useMutation(
    mutations.updateGroup,
    {
      onCompleted: () => {
        showToast('Group name updated successfully');
        setEditTitle(false);
        if (orgType === OrgTypeEnum.DefaultBrandGroups) refetchDefaultGroups();
        if (orgType === OrgTypeEnum.OrganisationBrandGroups)
          refetchOrganisationGroups();
      },
    }
  );

  useEffect(() => {
    if (currentOrgId) {
      getOrgBrands({ variables: { orgId: currentOrgId } });
    }
  }, [currentOrgId]);

  useEffect(() => {
    if (!tiersData) getTiers();
  }, []);

  const defaultGroups =
    floatingOrgsDefaultBrandingData?.seenit?.floatingOrgs || [];

  const defaultGroupsSelector = [
    { text: 'Please select a group', value: '-1' },
    ...defaultGroups.map(group => ({
      text: group.name,
      value: group.orgId,
    })),
  ];

  const organisationGroups =
    floatingOrgsOrgBrandingData?.seenit?.floatingOrgs || [];

  const organisationGroupsSelector = [
    { text: 'Please select a group', value: '-1' },
    ...organisationGroups.map(group => ({
      text: group.name,
      value: group.orgId,
    })),
  ];

  const { organisationSelector } = destructureDataForOrganisations(
    organisationsData
  );

  const tabs = [
    { dataEl: 'AfterEffects', id: 'afterEffects', value: 'afterEffects' },
    { dataEl: 'Opening', id: 'opening', value: 'openingMedia' },
    { dataEl: 'Closing', id: 'closing', value: 'closingMedia' },
    { dataEl: 'Audios', id: 'audios', value: 'audioTracks' },
    { dataEl: 'Watermarks', id: 'watermarks', value: 'watermarks' },
    { dataEl: 'Fonts', id: 'fonts', value: 'fonts' },
    { dataEl: 'Background', id: 'background', value: 'backgroundImage' },
    {
      dataEl: 'OrgAssets',
      id: 'orgAssets',
      value: 'orgAssets',
    },
    { dataEl: 'lowerThirds', id: 'lowerThirds', value: 'lowerThirds' },
    { dataEl: 'Brands', id: 'brands', value: 'brands' },
  ];

  const organisationConfigs = [
    'openingMedia',
    'closingMedia',
    'audioTracks',
    'watermarks',
    'fonts',
    'backgroundImage',
  ];

  const onChange = tab => {
    setCurrentTab(tab);
  };

  const onDeleteButtonClick = () => {
    setConfirmDeleteModal(true);
  };

  const rootElement = document.getElementById('root');

  if (tiersLoading) return <h1>Loading tiers</h1>;
  if (tiersError) return <h1>GQL Error</h1>;
  if (organisationsError) return <h1>GQL Error</h1>;

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Radio
        variant="round"
        onChange={value => {
          setOrgType(value);
          setCurrentBrandId(undefined);
          setCurrentOrgId(undefined);
        }}
        value={orgType}
        defaultValue="allOrganisations"
        options={[
          {
            title: 'Default brand groups',
            subtitle:
              'Set the default brands (for organisations without brands set)',
            value: OrgTypeEnum.DefaultBrandGroups,
            id: OrgTypeEnum.DefaultBrandGroups,
          },
          {
            title: 'Organisation brand groups',
            subtitle: 'Set brands to be shared across organisations',
            value: OrgTypeEnum.OrganisationBrandGroups,
            id: OrgTypeEnum.OrganisationBrandGroups,
          },
          {
            title: 'All organisations',
            subtitle:
              'Set an individual organisations brands. This cannot be shared between organisations.',
            value: OrgTypeEnum.AllOrganisations,
            id: OrgTypeEnum.AllOrganisations,
          },
        ]}
      />
      {orgType === OrgTypeEnum.DefaultBrandGroups && (
        <Styled.Wrapper>
          {currentOrgId ? (
            <Button
              width="300px"
              large
              onClick={() => setCurrentOrgId(undefined)}
            >
              Create New Group
            </Button>
          ) : (
            <CreateNewGroup
              onClick={() => {
                refetchDefaultGroups();
              }}
              type="default"
            />
          )}
          <Styled.Wrapper>
            <Typography variant="header">
              Select a group to configure
            </Typography>
            <Select
              options={defaultGroupsSelector}
              id="defaultGroups"
              dataEl="DefaultGroupsInput"
              onChange={value => {
                setCurrentOrgId(value);
                setOrgName(
                  defaultGroupsSelector.find(org => org.value === value).text
                );
              }}
              value={currentOrgId}
            />
          </Styled.Wrapper>
        </Styled.Wrapper>
      )}

      {orgType === OrgTypeEnum.OrganisationBrandGroups && (
        <Styled.Wrapper>
          {currentOrgId ? (
            <Button
              width="300px"
              large
              onClick={() => setCurrentOrgId(undefined)}
            >
              Create New Group
            </Button>
          ) : (
            <CreateNewGroup
              onClick={() => {
                refetchOrganisationGroups();
              }}
              type="organisation"
            />
          )}
          <Styled.Wrapper>
            <Typography variant="header">
              Select a group to configure
            </Typography>
            <Select
              options={organisationGroupsSelector}
              id={OrgTypeEnum.OrganisationBrandGroups}
              dataEl="OrganisationBrandGroupsInput"
              onChange={value => {
                setCurrentOrgId(value);
                setOrgName(
                  organisationGroupsSelector.find(org => org.value === value)
                    .text
                );
              }}
              value={currentOrgId}
            />
          </Styled.Wrapper>
        </Styled.Wrapper>
      )}

      {orgType === OrgTypeEnum.AllOrganisations && (
        <Styled.Wrapper>
          <Typography variant="header">
            Select a organisation to configure
          </Typography>
          <Select
            options={organisationSelector}
            id="organisations"
            dataEl="OrganisationsInput"
            onChange={value => {
              setCurrentOrgId(value);
              setOrgName(
                organisationSelector.find(org => org.value === value).text
              );
            }}
          />
        </Styled.Wrapper>
      )}
      {orgBrandsData && currentOrgId && (
        <Styled.BrandActionWrapper>
          {orgType === OrgTypeEnum.OrganisationBrandGroups ||
          orgType === OrgTypeEnum.DefaultBrandGroups ? (
            <ChangeTitle
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              onDelete={onDeleteButtonClick}
              onEnter={() =>
                updateGroup({
                  variables: { orgId: currentOrgId, data: { name: orgName } },
                })
              }
              setTitle={setOrgName}
              title={orgName}
              loading={groupDataLoading}
            />
          ) : (
            <Typography variant="header">{orgName}</Typography>
          )}

          <Styled.CardContentWrapper>
            {currentOrgId && currentOrgId !== '-1' && (
              <>
                <Tabs onChange={onChange} tabs={tabs} tabStyle="switch" />

                <Styled.TabContentWrapper>
                  {organisationConfigs.includes(currentTab) && (
                    <MediaUploadOrganism
                      orgType={orgType}
                      currentTab={currentTab}
                      currentOrgId={currentOrgId}
                      currentBrandId={currentBrandId}
                    />
                  )}

                  {['afterEffects'].includes(currentTab) && (
                    <AfterEffects orgId={currentOrgId} />
                  )}

                  {['orgAssets'].includes(currentTab) && (
                    <EditorOrgAssets orgId={currentOrgId} />
                  )}

                  {['lowerThirds'].includes(currentTab) && (
                    <LowerThirds orgId={currentOrgId} dataEl={dataEl} />
                  )}

                  {['brands'].includes(currentTab) && (
                    <Brands orgType={orgType} orgId={currentOrgId} />
                  )}
                </Styled.TabContentWrapper>
              </>
            )}
          </Styled.CardContentWrapper>
          {(orgType === OrgTypeEnum.DefaultBrandGroups ||
            orgType === OrgTypeEnum.OrganisationBrandGroups) && (
            <SimpleModal
              bodyMessageLineOne="Are you sure you want to delete this group?"
              bodyMessageLineTwo="This action cannot be undone."
              buttons={[
                {
                  onClick: () =>
                    deleteGroup({ variables: { orgId: currentOrgId } }),
                  text: 'Delete',
                  variant: 'outlineDanger',
                },
                {
                  onClick: () => setConfirmDeleteModal(false),
                  text: 'Cancel',
                  variant: 'outline',
                },
              ]}
              headerMessage="Delete Group"
              hintMessage={
                deleteGroupError &&
                'Error (You cannot delete a group that is being used)'
              }
              icon="delete"
              iconColor="danger"
              onClose={() => {
                setConfirmDeleteModal(false);
                resetDeleteGroup();
              }}
              visible={confirmDeleteModal}
            />
          )}
        </Styled.BrandActionWrapper>
      )}
      {!!rootElement &&
        createPortal(
          <Toast
            dataEl={`${dataEl}Toast`}
            autoClose="3000"
            containerId={`${dataEl}ToastContainer`}
            position="top-center"
            ref={toastRef}
            toastId={`${dataEl}Toast`}
          />,
          rootElement
        )}
    </Styled.Wrapper>
  );
}
