import { gql } from '@apollo/client';

export const queries = {
  getAeAssets: gql`
    query GetAeAssets($filter: EditorAdminAeAssetFilterInput) {
      seenit {
        aeAssets(filter: $filter) {
          aeAssetId
          name
          orgId
          source
          composition
          assets {
            layerName
            property
            assetType
            composition
            expression
            value
          }
        }
      }
    }
  `,
  getAeAsset: gql`
    query GetAeAsset($aeAssetId: ID!) {
      seenit {
        aeAsset(aeAssetId: $aeAssetId) {
          aeAssetId
          name
          orgId
          source
          composition
          aeAssetType
          assets {
            layerName
            property
            assetType
            composition
            expression
            value
          }
        }
      }
    }
  `,
};

export const mutations = {
  createAeAsset: gql`
    mutation CreateAeAsset($data: CreateAeAssetInput!) {
      seenit {
        createAeAsset(data: $data) {
          aeAssetId
        }
      }
    }
  `,
  updateAeAsset: gql`
    mutation UpdateAeAsset($aeAssetId: ID!, $data: UpdateAeAssetInput!) {
      seenit {
        updateAeAsset(aeAssetId: $aeAssetId, data: $data) {
          aeAssetId
        }
      }
    }
  `,
  deleteAeAsset: gql`
    mutation DeleteAeAsset($aeAssetId: ID!) {
      seenit {
        deleteAeAsset(aeAssetId: $aeAssetId) {
          success
        }
      }
    }
  `,
};
