/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createPortal } from 'react-dom';
import {
  Typography,
  TextInput,
  Select,
  Button,
  Toast,
  Pill,
} from '@team-seenit/atoms';
import { HexColorPicker } from 'react-colorful';
import { useLazyQuery, useMutation } from '@apollo/client';
import { mutations, queries } from '../EditorOrgAssets.gql';
import { EditorOrgAssetsFormProps } from './EditorOrgAssetsForm.types';
import * as Styled from '../EditorOrgAssets.styled';
import { Error } from '../../../../molecules';
import { FormError } from '../../FormError';

export const EditorOrgAssetsForm = ({
  orgId,
  selectedAsset,
  setSelectedAsset,
  refetchAssets,
}: EditorOrgAssetsFormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const toastRef = useRef();

  const rootElement = document.getElementById('root');

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const [
    createEditorOrgAsset,
    { error: createEditorOrgAssetError, reset: resetCreateEditorOrgAsset },
  ] = useMutation(mutations.createEditorOrgAsset, {
    onCompleted: () => {
      reset();
      refetchAssets();
      setSelectedAsset(null);
      showToast('Editor Org Asset created successfully');
    },
  });

  const [
    updateEditorOrgAsset,
    { error: updateEditorOrgAssetError, reset: resetUpdateEditorOrgAsset },
  ] = useMutation(mutations.updateEditorOrgAsset, {
    onCompleted: () => {
      reset();
      refetchAssets();
      setSelectedAsset(null);
      showToast('Editor Org Asset updated successfully');
    },
  });

  const [getEditorOrgAsset] = useLazyQuery(queries.getEditorOrgAsset, {
    onCompleted: data => {
      setValue('name', data?.seenit?.editorOrgAsset?.name);
      setValue('category', data?.seenit?.editorOrgAsset?.category);
      setValue('value', data?.seenit?.editorOrgAsset?.value);
    },

    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (selectedAsset)
      getEditorOrgAsset({ variables: { editorOrgAssetId: selectedAsset } });
    resetCreateEditorOrgAsset();
    resetUpdateEditorOrgAsset();
  }, [selectedAsset]);

  const onCreateClick = data => {
    createEditorOrgAsset({ variables: { data: { ...data, orgId } } });
  };

  const onUpdateClick = data => {
    updateEditorOrgAsset({
      variables: { data: { ...data }, editorOrgAssetId: selectedAsset },
    });
  };

  const validateHexCode = value => {
    const hexCodePattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i; // Regex for hex color code
    return hexCodePattern.test(value) || 'Invalid hex code';
  };

  return (
    <Styled.Wrapper>
      <Pill
        color="red"
        readOnly
        text={
          selectedAsset
            ? `Updating editor org asset`
            : `Creating editor org asset`
        }
      />
      <Typography variant="subheader">{selectedAsset}</Typography>
      <form
        onSubmit={handleSubmit(selectedAsset ? onUpdateClick : onCreateClick)}
      >
        <Styled.InputsWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Name is required',
            }}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography
                  lines={0}
                  variant="label"
                  css={{ marginBottom: '4px' }}
                >
                  Name
                </Typography>
                <TextInput
                  {...field}
                  value={getValues('name') ?? ''}
                  placeholder="Editor org asset name goes here..."
                />
                <FormError errors={errors} name="name" />
              </Styled.InputWrapper>
            )}
          />
          <Controller
            name="category"
            control={control}
            rules={{
              required: 'Category is a required field',
            }}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography
                  lines={0}
                  variant="label"
                  css={{ marginBottom: '4px' }}
                >
                  Category
                </Typography>
                <Select
                  options={[
                    { value: -1, text: 'Please select a category' },
                    { text: 'colors', value: 'colors' },
                  ]}
                  {...field}
                  onChange={val => {
                    if (val === '-1') {
                      setValue('category', null);
                    } else {
                      setValue('category', val);
                    }
                  }}
                />
                <FormError errors={errors} name="category" />
              </Styled.InputWrapper>
            )}
          />
          <Controller
            name="value"
            control={control}
            rules={{
              required: 'Hex Colour Code is required',
              validate: validateHexCode,
            }}
            render={({ field }) => (
              <Styled.InputWrapper>
                <Typography
                  lines={0}
                  variant="label"
                  css={{ marginBottom: '4px' }}
                >
                  Hex Code
                </Typography>
                <Styled.ColorPaletteWrapper>
                  <TextInput {...field} placeholder="Value goes here..." />
                  <FormError errors={errors} name="value" />
                  <HexColorPicker
                    color={field.value}
                    onChange={value => setValue('value', value)}
                  />
                </Styled.ColorPaletteWrapper>
              </Styled.InputWrapper>
            )}
          />

          <Button
            dataEl="MediaConfigSubmitButton"
            id="openingMediaSubmit"
            large
            type="submit"
            width="100%"
          >
            {selectedAsset ? 'Update' : 'Create'}
          </Button>
          <Error
            rawErrors={createEditorOrgAssetError || updateEditorOrgAssetError}
          />
          {!!rootElement &&
            createPortal(
              <Toast
                dataEl="EditorOrgAssetsToast"
                autoClose="3000"
                containerId="EditorOrgAssetsToastContainer"
                position="top-center"
                ref={toastRef}
                toastId="EditorOrgAssetsToast"
              />,
              rootElement
            )}
        </Styled.InputsWrapper>
      </form>
    </Styled.Wrapper>
  );
};
