import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@team-seenit/atoms';
import { StatsCard } from '@team-seenit/molecules';
import { useFormContext } from 'react-hook-form';
import * as Styled from '../AfterEffects.styled';
import { mutations } from '../AfterEffects.gql';
import { CurrentStateActionCard } from '../../CurrentStateActionCard';
import { AfterEffectsCurrentStateProps } from './AfterEffectsCurrentState.types';

export const AfterEffectsCurrentState = ({
  setSelectedAsset,
  selectedAsset,
  aeAssets,
  refetchAssets,
}: AfterEffectsCurrentStateProps) => {
  const { reset } = useFormContext();

  const [deleteAeAsset] = useMutation(mutations.deleteAeAsset, {
    onCompleted: data => {
      if (data?.seenit?.deleteAeAsset?.success) {
        refetchAssets();
        setSelectedAsset(null);
        reset();
      }
    },
  });

  return (
    <Styled.Wrapper>
      <StatsCard
        color="blue"
        icon="brand_editor"
        stats={aeAssets?.length}
        title="Total"
      />
      <Button
        onClick={() => {
          reset();
          setSelectedAsset(null);
        }}
      >
        Reset form / Create AE asset
      </Button>
      {aeAssets?.map(item => {
        return (
          <CurrentStateActionCard
            key={item.aeAssetId}
            id={item.aeAssetId}
            onDeleteClick={() =>
              deleteAeAsset({
                variables: { aeAssetId: item.aeAssetId },
              })
            }
            disabled={selectedAsset === item.aeAssetId}
            name={item.name}
            onCardClick={() => setSelectedAsset(item.aeAssetId)}
          />
        );
      })}
    </Styled.Wrapper>
  );
};
