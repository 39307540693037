import React, { Dispatch, SetStateAction } from 'react';
import { IconButton, Typography, TextInput } from '@team-seenit/atoms';
import * as Styled from './ChangeTitle.styled';

export const ChangeTitle = ({
  onEnter,
  onDelete,
  title,
  setTitle,
  loading,
  editTitle,
  setEditTitle,
}: {
  onEnter: () => void;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  loading: boolean;
  editTitle: boolean;
  setEditTitle: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
}): JSX.Element => {
  const onKeyPressed = event => {
    if (['Enter', 'Tab'].includes(event.key)) {
      if (!title.length || loading) return;
      if (title.length) {
        onEnter();
      }
    }
  };

  return (
    <>
      {!editTitle && (
        <Styled.TitleWrapper>
          <IconButton
            icon="edit"
            onClick={() => setEditTitle(true)}
            variant="transparentAccent"
          />
          <Typography variant="header">{title}</Typography>
          <IconButton icon="delete" onClick={onDelete} variant="outlineRed" />
        </Styled.TitleWrapper>
      )}
      {editTitle && (
        <Styled.InputField>
          <TextInput
            value={title}
            onKeyDown={onKeyPressed}
            valid={title.length}
            placeholder="Image title"
            onChange={value => setTitle(value)}
          />
        </Styled.InputField>
      )}
    </>
  );
};
